/* You can add global styles to this file, and also import other style files */
/* body {
    padding-top: 80px;
} */

.form-signin {
    max-width: 330px;
    padding: 15px;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


  .header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    background: #392A1D;
    color: white !important;
}

.dialogTitle{
  color:white !important;
}

/* Table styles*/

table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: rgb(229, 16, 16);
}

.tableTitle {
  font-size: calc(1.125rem + .9vw);
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

/* .mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color {
  margin-right: 10px;
  cursor: pointer;
} */

.actionIcon {
  margin: 1px;
  cursor: pointer;
}

.tableContent {
  margin-top: 10px;
}

.searchButton {  
 text-align: right;
}

.actionButton {
  background-color: #392A1D !important;
  color: white !important;
  }

.actionButton:disabled
{
  background-color: #adaba9!Important;
}

.leftActionButton {
  margin-right: 10px;
}